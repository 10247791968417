import React from 'react';
import cx from 'classnames';
import s from './ResponsiveLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {Cell} from '../Cell/Cell';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {ModalGalleryLayout} from '../../ModalGallery/ModalGalleryLayout';
import {Mode, WishlistButton} from '../../ProductPageButtonsContainer/WishlistButton/WishlistButton';
import {Navigation, NavigationType} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {ProductName} from '../../ProductName/ProductName';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {ProductSku} from '../../ProductSku/ProductSku';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ResponsiveGallery} from './ResponsiveGallery/ResponsiveGallery';
import {ShowOnMobileOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnMobileOnly/ShowOnMobileOnly';
import {withGlobalProps} from '../../../providers/globalPropsProvider';
@withGlobalProps
export class ResponsiveLayout extends React.Component<LayoutComponentProps> {
  private renderLeftCol() {
    const {
      product,
      settings,
      globals: {shouldShowWishlistButton},
    } = this.props;

    return (
      <section className={cx(s.col, s.left)}>
        <Cell className={s.gallery}>
          <ModalGalleryLayout>
            {(modalZoom) => (
              <ResponsiveGallery
                media={product.media}
                imageMode={settings.imageMode}
                imageRatio={settings.imageRatioId}
                openZoom={modalZoom.open}
                withZoom={modalZoom.isEnabled}
              />
            )}
          </ModalGalleryLayout>
          {shouldShowWishlistButton && (
            <ShowOnMobileOnly>
              <WishlistButton mode={Mode.FLOATING} />
            </ShowOnMobileOnly>
          )}
        </Cell>
      </section>
    );
  }

  private renderRightCol() {
    const {product, settings} = this.props;

    return (
      <section className={cx(s.col, s.right)}>
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}
        <Cell className={s.name}>
          <ProductName name={product.name} />
        </Cell>
        {settings.shouldShowPrice && (
          <Cell>
            <ProductPriceContainer className={s.price} />
          </Cell>
        )}
        <Cell>
          <DetailsPlaceholder />
        </Cell>
        <Cell className={s.options}>
          <ProductOptions shouldShowQuantity={settings.shouldShowQuantity} />
          <ProductPageButtonsContainer />
        </Cell>
        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}
        {settings.shouldShowSocialNetwork && (
          <Cell className={s.social}>
            <ProductSocial />
          </Cell>
        )}
      </section>
    );
  }

  public render(): JSX.Element {
    const {isMobile} = this.props.globals;
    const renderNavigation = this.props.settings.shouldShowNavigation || isMobile;

    return (
      <article className={s.container}>
        {renderNavigation && (
          <Navigation className={s.navigation} renderBreadcrumbs={isMobile} type={NavigationType.Short} />
        )}
        <div className={s.main}>
          {this.renderLeftCol()}
          {this.renderRightCol()}
        </div>
        <Cell>
          <BottomPlaceholder />
        </Cell>
      </article>
    );
  }
}

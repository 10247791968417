import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {OrderItem} from '@wix/cashier-express-checkout-widget/dist/src/types/OrderItem';
import {Shape} from '@wix/cashier-express-checkout-widget/dist/src/types/Styles';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {BreakdownTypes} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {
  ShippingContactSelectedUpdate,
  ShippingContactRestricted,
} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import loadable from '@loadable/component';
import {withPropsChangeListeners, WithPropsChangeListenersProps} from '../../../providers/listenToPropsChanges';
import {OnPaymentAuthorizedResult} from '@wix/cashier-express-checkout-widget/src/types/ExternalContract';

const CashierExpressCheckoutWidgetLazy = loadable<WidgetProps>(() =>
  import(
    /* webpackChunkName: "CashierExpressCheckoutWidget" */ '@wix/cashier-express-checkout-widget/dist/src/lazy'
  ).then((module) => module.CashierExpressCheckoutWidget)
);

@withGlobalProps
@withPropsChangeListeners
export class CashierExpressButton extends React.Component<ProvidedGlobalProps & WithPropsChangeListenersProps> {
  public getButtonStyle() {
    // const {fonts} = this.props.siteStore;
    // if (fonts.cartButton_cornersRadius) {
    //   if (fonts.cartButton_cornersRadius.value === '0px' || fonts.cartButton_cornersRadius.value === '5px') {
    //     return {shape: 'rect' as Shape, height: 42};
    //   } else {
    //     return {shape: 'pill' as Shape, height: 42};
    //   }
    // } else {
    //   return {shape: 'rect' as Shape, height: 42};
    // }
    return {shape: 'rect' as Shape, height: 42};
  }

  public handleCashierPaymentSubmit = async (
    paymentInfo: PaymentAuthorizedArgs
  ): Promise<OnPaymentAuthorizedResult> => {
    if (paymentInfo.error) {
      throw paymentInfo.error;
    }

    this.props.globals.handleCashierPaymentSubmit(paymentInfo, this.props.globals.accessibilityEnabled);
    return this.props.waitForChange('handleCashierPaymentSubmitResult');
  };

  public handleExpressCashierShippingAddressChange = async (
    shippingAddress: ShippingContactRestricted
  ): Promise<ShippingContactSelectedUpdate> => {
    this.props.globals.fetchPaymentBreakdownForCashierAddress(shippingAddress);
    return this.props.waitForChange('fetchPaymentBreakdownForCashierAddressResult');
  };

  public onClick = async () => {
    // todo(eran): results can't be tested with current cashier testKit because this callback doesn't actually opens cashier modal in tests
    this.props.globals.handleCashierOnClick();
    if (await this.props.waitForChange('handleCashierOnClickResult')) {
      return Promise.resolve({canceled: false});
    } else {
      return Promise.resolve({canceled: true});
    }
  };

  private readonly getCartItemsForCashier = (): OrderItem[] => {
    const {id, name, price} = this.props.globals.product;
    const quantity = this.props.globals.userInputs.quantity[0];
    return [{id, name, price: price.toString(), quantity}];
  };

  public render() {
    const {meta, currency, locale} = this.props.globals.cashierExpressCheckoutWidgetProps;
    const {selectedVariant, product} = this.props.globals;
    const price = (selectedVariant || product).price;

    const payment = {
      total: price.toString(),
      paymentBreakdown: {
        [BreakdownTypes.ItemsTotal]: price.toString(),
        [BreakdownTypes.Shipping]: '0',
        [BreakdownTypes.Tax]: '0',
        [BreakdownTypes.Discount]: '0',
      },
    };

    return (
      <>
        {'CashierExpressCheckoutWidgetLazy render'}
        <CashierExpressCheckoutWidgetLazy
          buttonStyle={this.getButtonStyle()}
          currency={currency}
          locale={locale}
          //@ts-expect-error: todo(eran): fix
          meta={meta}
          onClick={this.onClick}
          onPaymentAuthorized={this.handleCashierPaymentSubmit}
          // onPaymentAuthorizedInit={this.showLoader}
          onShippingContactSelected={this.handleExpressCashierShippingAddressChange}
          orderItems={this.getCartItemsForCashier()}
          paymentAmount={payment.total}
          paymentBreakdown={payment.paymentBreakdown}
          paymentLabel="forApplePay"
          requestShipping={true}
          // requestShipping={this.shouldRequestShipping()}
        />
      </>
    );
  }
}
